@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'visby';
  src: url('../public/static/fonts/VisbyCF-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'visby';
  src: url('../public/static/fonts/VisbyCF-Regular.otf');
  font-weight: 400;
}

@layer base {
  html,
  body {
    @apply max-w-full font-rc-body font-normal text-rc-quilladin bg-rc-litten scroll-pt-[200px] scroll-smooth;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .btn {
    @apply btn--base btn--size btn--pseudo bg-rc-sandaconda text-white text-sm [&:not([disabled])]:hover:bg-rc-quilladin;
  }

  .btn--light {
    @apply btn--base btn--size btn--pseudo bg-rc-shedinja text-white text-sm [&:not([disabled])]:hover:bg-rc-quilladin;
  }

  .btn--dark {
    @apply btn--base btn--size btn--pseudo bg-rc-quilladin text-white text-sm [&:not([disabled])]:hover:bg-rc-shedinja;
  }

  .btn--white {
    @apply btn--base btn--size btn--pseudo bg-white text-rc-shedinja text-sm [&:not([disabled])]:hover:text-white [&:not([disabled])]:hover:bg-rc-shedinja;
  }

  .btn--white-alt {
    @apply btn--base btn--size btn--pseudo bg-white text-rc-sandaconda text-sm [&:not([disabled])]:hover:text-white [&:not([disabled])]:hover:bg-rc-sandaconda;
  }

  .sub--title {
    @apply text-2xl md:text-3xl lg:text-4xl font-rc-plus font-bold;
  }

  .section {
    @apply section--top section--bottom;
  }

  .content {
    @apply content--base content--pseudo content--paragraphs content--headings;
  }

  .top--triangle-wrapper {
    @apply overflow-hidden w-full absolute left-0 top-0;
  }

  .top--triangle {
    @apply inset-x-0 top-0 w-0 h-0 overflow-hidden relative block border-t-[100px] lg:border-t-[140px] border-t-rc-sandaconda -translate-x-[20vw] border-l-[70vw] border-l-transparent border-r-[70vw] border-r-transparent;
  }

  .clip--triangle {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }
}

@layer utilities {
  .btn--pseudo {
    @apply transition-all disabled:opacity-75 focus:scale-95;
  }

  .btn--size {
    @apply py-2 px-4 sm:px-5 max-sm:text-sm;
  }

  .btn--base {
    @apply rounded-full inline-flex font-bold font-rc-body;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .content--pseudo {
    @apply children-a:underline hover:children-a:text-rc-shedinja;
  }

  .content--base {
    @apply last:[&>*]:mb-0 children-b:font-bold children-strong:font-bold children-b:font-rc-plus children-strong:font-rc-plus;
  }

  .content--paragraphs {
    @apply max-md:children-p:text-sm max-sm:children-p:leading-6 sm:children-p:leading-6 lg:children-p:leading-7 children-p:mb-4 font-bold font-rc-primary children-ul:mb-4;
  }

  .content--headings {
    @apply children-headings:font-rc-plus children-headings:mb-4 children-h1:mb-5 children-h1:text-xl lg:children-h1:text-2xl xl:children-h1:text-3xl children-h2:sub--title children-headings:font-bold;
  }

  .section--top {
    @apply pt-10 sm:pt-14 lg:pt-24;
  }

  .section--bottom {
    @apply pb-10 sm:pb-14 lg:pb-24;
  }

  .svg--white {
    filter: brightness(0) invert(1);
  }

  .cart--total {
    @apply text-lg lg:text-xl font-bold;
  }

  .cart--grid--gap {
    @apply gap-4 sm:gap-6 lg:gap-8 xl:gap-10;
  }

  .shadow--box {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }

  .shadow--filter {
    filter: drop-shadow(0 0 5px rgb(0 0 0 / 0.25));
  }

  .webkit--appearance--none::-webkit-outer-spin-button,
  .webkit--appearance--none::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  .product--image {
    @apply w-full h-full scale-[1.35] object-cover;
  }

  .product--image--wrapper {
    @apply aspect-[16/10] overflow-hidden bg-rc-quilladin;
  }
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  @apply bg-rc-shedinja;
}

#nprogress .spinner-icon {
  @apply hidden;
}

#nprogress .peg {
  @apply shadow;
}

/* Form helpers */

.form-duuf-submit > button {
  @apply btn;
}

.form-duuf-group {
  @apply mb-4 lg:mb-5;
}

.form-duuf-loading {
  @apply absolute inset-0 z-10 bg-white/50 flex-center;
}

.form-duuf-label {
  @apply block;
}

.form-duuf-checkbox-consent {
  @apply mr-2 max-md:text-sm;
}

.form-duuf-group-consent a {
  @apply underline;
}

.form-duuf-group-submit {
  @apply mb-0;
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply rounded-full border border-rc-quilladin text-black w-full py-1 md:py-2 px-4 md:px-5;
}

.form-duuf-select {
  @apply appearance-none [background-position-x:calc(100%-theme(space.2))] [background-position-y:4px] md:[background-position-y:8px] bg-no-repeat bg-transparent;

  background-image: url('/static/chevron-down.svg');
}

.form-duuf-textarea {
  @apply h-40 rounded-3xl;
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-file-button button {
  @apply btn--light py-1 px-2;
}

.form-duuf-error {
  @apply text-sm text-red-700 italic;
}

/* Custom */

[aria-current='page'] .active-dot {
  @apply block;
}

.receipt {
  --mask: conic-gradient(from -45deg at bottom, #0000, #000 1deg 89deg, #0000 90deg) 50%/20px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

/* Datepicker */

.react-datepicker {
  @apply !bg-white !border !border-white !rounded-xl !shadow !font-rc-primary;
}

.react-datepicker__header {
  @apply !bg-white;
}

.react-datepicker__triangle {
  @apply !hidden;
}

.react-datepicker__day--selected {
  @apply !bg-rc-shedinja;
}

.react-datepicker__day,
.react-datepicker__day-name {
  @apply !w-7 !h-6 !leading-6;
}

.react-datepicker__current-month {
  @apply !font-rc-plus !font-bold;
}
